import React from 'react'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import { IndexQueryQuery, PostByPathQuery } from '../../../types/graphql-types'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import Video from 'components/video'
import BackgroundImage from 'gatsby-background-image'
import OGImage from 'images/the-dentists-at-gateway-crossing.jpg'
import Logo from 'images/logo.png'
import { Helmet } from 'react-helmet'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const PrivacyPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  return (
    <Layout location={location}>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>Privacy Policy  - The Dentists At Gateway Crossing</title>
      <meta name="description" content="It is The Dentists at Gateway Crossing’s policy to respect your privacy regarding any information we may collect while operating our website." />
      <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Privacy Policy - The Dentists At Gateway Crossing" />
      <meta property="og:description" content="It is The Dentists at Gateway Crossing’s policy to respect your privacy regarding any information we may collect while operating our website." />
      <meta property="og:url" content="https://thedentistsatgc.com/privacy-policy/" />
      <meta property="og:site_name" content="The Dentists At Gateway Crossing" />
      <meta property="og:updated_time" content="2021-02-05T05:10:25+00:00" />
      <meta property="og:image" content={OGImage} />
      <meta property="og:image:secure_url" content={OGImage} />
      <meta property="og:image:width" content="1080" />
      <meta property="og:image:height" content="1080" />
      <meta property="og:image:alt" content="McCordsville Dentist" />
      <meta property="og:image:type" content="image/jpeg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Privacy Policy - The Dentists At Gateway Crossing" />
      <meta name="twitter:description" content="It is The Dentists at Gateway Crossing’s policy to respect your privacy regarding any information we may collect while operating our website." />
      <meta name="twitter:image" content={OGImage} />
      <meta name="google-site-verification" content="khYQGQ-T8YWkXa0QyKr6e2kNlFB3l0ZiFRA89VzVbaM" />
      <meta name="google-site-verification" content="Oll6PjpMRzNx1KbgKilxS6xPddvSbF2lDOa2rd7VkxA" />
      <meta name="msapplication-TileImage" content={Logo} />
      <script async src='https://tag.simpli.fi/sifitag/195cd770-bf42-013a-5143-0cc47a8ffaac'></script>
    </Helmet>
        <div id="hero-section">
          <Container>
            <div className= "col-lg-12">
              <h1> Privacy Policy </h1>
            </div>
            <div className= "col-lg-12">
              <div className="row">
                <div className = "col-lg-5">
                  <p className="m-t-20">Welcome to our Privacy Policy
                    — Your privacy is critically important to us.
                    The Dentists at Gateway Crossing is located at: </p>
                </div>
              </div>
              <div className="row">
                <div className ="col-lg-5">
                  <p>The Dentists at Gateway Crossing<br />
                  6621 w broadway STE 600<br />
                  Indiana, United States<br />
                  3176439434</p>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div id ="policy-section-2" className ="page-section">
          <Container>
          <div className ="col-lg-12">
            <p>It is The Dentists at Gateway Crossing’s policy to respect your privacy regarding any information we may collect while operating our website. This Privacy Policy applies to https://adrienbird.com/thedentistsatgc (hereinafter, “us”, “we”, or “https://adrienbird.com/thedentistsatgc”). We respect your privacy and are committed to protecting personally identifiable information you may provide us through the Website. We have adopted this privacy policy (“Privacy Policy”) to explain what information may be collected on our Website, how we use this information, and under what circumstances we may disclose the information to third parties. <br />
            This Privacy Policy applies only to information we collect through the Website and does not apply to our collection of information from other sources. This Privacy Policy, together with the Terms and conditions posted on our Website, set forth the general rules and policies governing your use of our Website. Depending on your activities when visiting our Website, you may be required to agree to additional terms and conditions.</p>
          </div>
          <div className ="col-lg-12">
            <p><strong>Website Visitors </strong></p>
            <p>Like most website operators, The Dentists at Gateway Crossing collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. The Dentists at Gateway Crossing’s purpose in collecting non-personally identifying information is to better understand how The Dentists at Gateway Crossing’s visitors use its website. From time to time, The Dentists at Gateway Crossing may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website.<br />
            The Dentists at Gateway Crossing also collects potentially personally-identifying information like Internet Protocol (IP) addresses for logged in users and for users leaving comments on https://adrienbird.com/thedentistsatgc blog posts. The Dentists at Gateway Crossing only discloses logged in user and commenter IP addresses under the same circumstances that it uses and discloses personally-identifying information as described below.</p>
          </div>
          <div className ="col-lg-12">
            <p><strong>Gathering of Personally-Identifying Information </strong></p>
            <p>Certain visitors to The Dentists at Gateway Crossing’s websites choose to interact with The Dentists at Gateway Crossing in ways that require The Dentists at Gateway Crossing to gather personally-identifying information. The amount and type of information that The Dentists at Gateway Crossing gathers depends on the nature of the interaction. For example, we ask visitors who sign up for a blog at https://adrienbird.com/thedentistsatgc to provide a username and email address. </p>
          </div>
          <div className ="col-lg-12">
            <p><strong>Security </strong></p>
            <p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>
          </div>
          <div className ="col-lg-12">
            <p><strong>Advertisments </strong></p>
            <p>Ads appearing on our website may be delivered to users by advertising partners, who may set cookies. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This Privacy Policy covers the use of cookies by The Dentists at Gateway Crossing and does not cover the use of cookies by any advertisers. </p>
          </div>
        </Container>
        <Container>
          <div className ="col-lg-12">
            <p><strong>Links To External Sites </strong></p>
            <p>Our Service may contain links to external sites that are not operated by us. If you click on a third party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy and terms and conditions of every site you visit.<br />
            We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party sites, products or services.</p>
          </div>
          <div className ="col-lg-12">
            <p>Aggregated Statistics </p>
            <p>The Dentists at Gateway Crossing may collect statistics about the behavior of visitors to its website. The Dentists at Gateway Crossing may display this information publicly or provide it to others. However, The Dentists at Gateway Crossing does not disclose your personally-identifying information.</p>
          </div>
        </Container>
        <Container>
          <div className ="col-lg-12">
            <p><strong>Cookies</strong></p>
            <p> To enrich and perfect your online experience, The Dentists at Gateway Crossing uses “Cookies”, similar technologies and services provided by others to display personalized content, appropriate advertising and store your preferences on your computer.<br />
            A cookie is a string of information that a website stores on a visitor’s computer, and that the visitor’s browser provides to the website each time the visitor returns. The Dentists at Gateway Crossing uses cookies to help The Dentists at Gateway Crossing identify and track visitors, their usage of https://adrienbird.com/thedentistsatgc, and their website access preferences. The Dentists at Gateway Crossing visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using The Dentists at Gateway Crossing’s websites, with the drawback that certain features of The Dentists at Gateway Crossing’s websites may not function properly without the aid of cookies.<br />
            By continuing to navigate our website without changing your cookie settings, you hereby acknowledge and agree to The Dentists at Gateway Crossing’s use of cookies. </p>
          </div>
          <div className ="col-lg-12">
            <p><strong>Privacy Policy Changes </strong></p>
            <p>Although most changes are likely to be minor, The Dentists at Gateway Crossing may change its Privacy Policy from time to time, and in The Dentists at Gateway Crossing’s sole discretion. The Dentists at Gateway Crossing encourages visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.</p>
          </div>
          <div className ="col-lg-12">
            <p><strong>Contact Information </strong></p>
            <p> If you have any questions about this Privacy Policy, please contact us via email or phone.</p>
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default PrivacyPage

export const query = graphql`
  query PrivacyPageQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
      }
    }
  }
`
